import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import md from "../helpers/md"

const NotFoundPage = ({ data }) => (
  <Layout>
    <Seo title="404: Not found" />
    {md({ data })}
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/404/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`

export default NotFoundPage
