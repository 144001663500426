import React from "react"

export const md = data => {
  const { frontmatter, html } = data.data.allMarkdownRemark.edges[0].node
  return (
    <div>
      <h1>{frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default md
